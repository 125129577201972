import styled from '@emotion/styled'
import { FeatureFlag, LocalStorageUtil } from 'cuenect-web-core'
import { useFeatureFlags } from 'cuenect-web-core'
import { rgba } from 'emotion-rgba'
import { navigate } from 'gatsby'
import parse from 'html-react-parser'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ContentContainer, LoadingIndicator } from '../components'
import {
  AGENDA_SHOW_BOOKMARKS,
  Heading,
  LineContainer,
  Paragraph,
  ProgramContainer,
  ProgramEntryPlaceholder,
  SieArrow,
} from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { LocalData } from './../api'
import {
  eventConfig,
  isPostEventFirst,
  isPostEventAppointments,
} from './../config'
import { mq } from './../utility/breakpoint'

const AppointmentsPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params
  const [loaded, setLoaded] = React.useState(false)
  const [hasEntries, setHasEntries] = React.useState(true)
  const [agendaLoaded, setAgendaLoaded] = React.useState(false)
  const { phase2, phase2b } = useFeatureFlags()

  const [selectedBookmarks, setSelectedBookmarks] = React.useState<boolean>(
    LocalStorageUtil.getString(AGENDA_SHOW_BOOKMARKS) === 'true'
  )

  if (lang === '') {
    if (typeof window !== 'undefined') {
      navigate(`/en/${document.location.search}`)
    }

    return <></>
  }

  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])
  const { t } = useTranslation(['appointments', 'dialog'])

  const joinMeeting = url => {
    const userData = LocalStorageUtil.getObject<LocalData | null>('user')
      ?.person

    window.open(url)
  }

  if (!hasEntries && isPostEventAppointments()) {
    navigate('/')
    return <></>
  }

  return (
    <PageFrame {...params} pageName="appointments">
      {loaded && (
        <>
          <ContentContainer align="center">
            {agendaLoaded && (
              <>
                {hasEntries || !phase2 ? (
                  <StartTeaser width={66}>
                    <LineContainer className="LineContainer">
                      <Heading type="h2" spacing={{ top: 0.5, bottom: 0.4 }}>
                        {parse(t('teaser.header'))}
                      </Heading>
                    </LineContainer>
                    <Paragraph>
                      {t('teaser.copy')}
                      <FeatureFlag name="phase2">
                        <br />
                        <br />

                        <Button
                          plain
                          colored
                          small
                          icon={SieArrow}
                          active
                          onClick={() => joinMeeting(eventConfig.testSession)}
                        >
                          {t('teaser.ctaButton')}
                        </Button>
                      </FeatureFlag>
                    </Paragraph>
                  </StartTeaser>
                ) : (
                  <StartTeaser width={66}>
                    <LineContainer className="LineContainer">
                      <Heading type="h2" spacing={{ top: 0.5, bottom: 0.4 }}>
                        {parse(t('unregTeaser.header'))}
                      </Heading>
                    </LineContainer>
                    <Paragraph>
                      {parse(t('unregTeaser.copy'))}
                      {phase2 && (
                        <>
                          <br />
                          <br />

                          <Button
                            onClick={() =>
                              (document.location.href = t(
                                'unregTeaser.ctaLink'
                              ))
                            }
                          >
                            {t(
                              isPostEventFirst()
                                ? 'unregTeaser.ctaButtonPostEvent'
                                : 'unregTeaser.ctaButton'
                            )}
                          </Button>
                        </>
                      )}
                    </Paragraph>
                  </StartTeaser>
                )}
              </>
            )}
            {!agendaLoaded && (
              <LoadingIndicatorContainer>
                <LoadingIndicator />
              </LoadingIndicatorContainer>
            )}
          </ContentContainer>
          {hasEntries || !phase2 ? (
            <ContentContainer>
              <ProgramContainer
                dateDisplay={'dateDisplayGroup'}
                source={eventConfig.sources[lang]}
                showBookmarks={false}
                showOnlyParticipations={true}
                showAppointment={true}
                hideTestSession={true}
                onLoaded={entries => {
                  setAgendaLoaded(true)
                  if (entries.length > 0) {
                    setHasEntries(true)
                  } else {
                    setHasEntries(false)
                  }
                }}
              />
            </ContentContainer>
          ) : (
            <ContentContainer>
              <Heading type="h5">{t('emptyAgenda.header')}</Heading>
              <AgendaPlaceholder>
                <ProgramEntryPlaceholder />
              </AgendaPlaceholder>
            </ContentContainer>
          )}
        </>
      )}
    </PageFrame>
  )
}

export default AppointmentsPage

interface StartTeaserProps {
  fullheight?: boolean
  width?: number
  align?: string
  border?: boolean
}

const StartTeaser = styled.div<StartTeaserProps>(
  ({
    theme: { colors, grid },
    fullheight,
    width,
    border = false,
    align = 'left',
  }) => `
  display:flex;
  flex-direction:column;
  ${[mq[3]]}{
    ${width && ` width: ${width}%;`}
  }
  ${
    fullheight
      ? `min-height:calc(100vh - 220px);
    ${[mq[3]]}{
      display:flex;
      align-items:center;
    }`
      : ''
  }

  ${border ? `border:1px solid ${colors.inputBorder}; padding:40px;` : ''}


  margin-top:120px;
  margin-left:${100 / grid.columns}vw;
  h1{
    margin:0;
  }
  p, .LineContainer{ margin-bottom:50px;}
`
)

const Spacer = styled.div`
  margin: 95px 0;
  ${mq[3]} {
    margin: 195px 0;
  }
`

const AgendaPlaceholder = styled.div(
  ({ theme: { colors } }) => `
  border:1px solid ${rgba(colors.tableBorder, 0.5)};
  padding:0 40px;
`
)
const LoadingIndicatorContainer = styled.div(
  ({ theme: { colors } }) => `
 height:100vh;
 display:flex;align-items:center;
 justify-content:center;
`
)
